var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{staticClass:"footer"},[_c('v-card-text',{staticClass:"center"},[_c('div',{staticClass:"mt-0 mx-8",style:({
      backgroundColor: _vm.home ? 'transparent' : 'rgb(239, 126, 53)',
      
    }),on:{"click":_vm.homeClick}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"width":"24px","src":require("@/assets/home.png")}})])],1),_c('div',{staticClass:"mt-0 mx-8",style:({
      backgroundColor: _vm.account ? 'transparent' : 'rgb(239, 126, 53)',
      
    }),on:{"click":_vm.accountClick}},[_c('router-link',{attrs:{"to":"/MyProfilePage"}},[_c('img',{attrs:{"width":"20px","src":require("@/assets/person.png")}})])],1),_c('div',{staticClass:"mt-0 mx-8",style:({
      backgroundColor: _vm.ticket ? 'transparent' : 'rgb(239, 126, 53)',
      
    }),on:{"click":_vm.ticketClick}},[_c('router-link',{attrs:{"to":"/TicketPage"}},[_c('img',{attrs:{"width":"22px","src":require("@/assets/ticket.png")}})])],1),_c('div',{staticClass:"mt-0 mx-8",style:({
      backgroundColor: _vm.order ? 'transparent' : 'rgb(239, 126, 53)',
      
    }),on:{"click":_vm.orderClick}},[_c('router-link',{attrs:{"to":"/OrdersPage"}},[_c('img',{attrs:{"width":"20px","height":"auto","src":require("@/assets/orders.png")}})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }